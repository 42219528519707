<script>
import timeprizepool from "@/components/realTtimeorder/timePrizePool";

export default {
  name: "Timeprizepool",
  extends: timeprizepool,
}
</script>

<template>
  <div class="syj_timeprizepool syj_page-width">
    <ul>
      <li class="listprize0">
        <p><i>[</i>奖池总金额<i>]</i></p>
        <div class="timenum">
          <!-- <span
            v-for="(timenum, key) in jackpot_amount.toString().split('')"
            :key="key"
          >
            {{ timenum }}
          </span> -->
          <span v-for="(item, index) in jackpotamount" :key="index">
            <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
              {{ indexs }}
            </div>
          </span>
        </div>
      </li>
      <li class="listprize1">
        <p><i>[</i>参与人数<i>]</i></p>
        <div class="timenum">
          <!-- <span
            v-for="(timenum, key) in participants_count.toString().split('')"
            :key="key"
          >
            {{ timenum }}
          </span> -->
          <span v-for="(item, index) in jennycount" :key="index">
            <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
              {{ indexs }}
            </div>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="less">
.syj_timeprizepool {
  padding-bottom: 0;
  ul {
    margin-top: 60px;
    li {
      width: 180px;
      height: 92.48px;
      margin: 0;
      > p {
        font-size: 13px;
        margin-top: 6px;
      }
      
      .timenum {
        font-size: 17px;
        height: 25px;
        span {
          display: flex;
          flex-direction: column;
          width: 18px;
          height: 25px;
          line-height: 25px;
          >div {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
