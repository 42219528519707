<template>
  <div class="live-order" id="topidtime">
    <div class="empty" v-if="ingstartnew==0">
      <div class="empty_content">
        <h2 class="date">2024</h2>
        <h1 class="title">黑五竞猜</h1>
        <p>活动尚未开始敬请期待...</p>
      </div>
      
      <img class="bg" src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20241107-084238.jpeg"/>
    </div>
    
    <div v-else-if="ingstartnew==1" class="syj_realttime">
      <!-- logo 个人中心 -->
      <toplogoCustomer-info></toplogoCustomer-info>
<!--      <div class="syj_realttime-content">-->
<!--        <div class="syj_pagetime">-->
<!--          <countDown v-if="countdowndata" :countdowndata="countdowndata"/>-->
<!--        </div>-->
<!--      </div>-->
      <!-- 客户留言弹幕  start -->
      <!--      <message-info></message-info>-->
      <!--      <div class="sideicon">-->
      <!--        &lt;!&ndash; 游戏规则 &ndash;&gt;-->
      <!--        <div class="popiconguestule" @click="isopenguestule">-->
      <!--          <img src="/assets/images/guessruleicon.png"/>-->
      <!--        </div>-->
      <!--        &lt;!&ndash; 趋势对比图&ndash;&gt;-->
      <!--        <div class="isonchart" @click="ishomechart">-->
      <!--          <img src="/assets/images/charticon.png"/>-->
      <!--        </div>-->
      <!--        &lt;!&ndash; 排行榜&ndash;&gt;-->
      <!--        <div class="tophome isonchart" @click="istop()">-->
      <!--          <img src="/assets/images/hometop.png"/>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="syj-realtime-bottombg">
        
        <!-- 历届奖励 start -->
        <!--        <homehistoryWin-info :scrolllunbotime="scrolllunbotime"></homehistoryWin-info>-->
        
        <!--  奖金池 start-->
        <timeprizepool-info></timeprizepool-info>
        
        <guestrule-info v-if="onOpenguestule" @isGuessClose="isguessclose"></guestrule-info>
        <homecomparisonchart-info v-if="onishomechart" @ishomeChartclose="ishomechartclose"></homecomparisonchart-info>
        <!-- 实时订单-->
        <realTtimeorder-info v-if="startime && endtime" :starttime="startime" :endtime="endtime"
                             :timeorderNum="timeorderNum"></realTtimeorder-info>
      </div>
    </div>
  
  </div>
</template>
<script>
// import countDown from "@/components/realTtimeorder/countDown";
// import message from "@/components/message";
import guestrule from "@/components/guestRule";
// import homehistoryWin from "@/components/homeHistoryWin";
// import timeprizepool from "@/components/realTtimeorder/timePrizePool";
import Timeprizepool from './components/Timeprizepool.vue'


import homecomparisonchart from "@/components/homeComparisonchart";
// import realtimeorder from "@/components/realTtimeorder/realTimeorder";
import Realtimeorder from './components/Realtimeorder.vue'

import ToplogoCustomer from "./components/ToplogoCustomer.vue";
import {currentActivity} from "@/units/api.js";
import {setActivity} from "@/units/activity";

export default {
  name: "timeorderMian",
  data() {
    return {
      countdowndata: null,
      onOpenguestule: false,
      onishomechart: false,
      startime: null,
      endtime: null,
      ingstartnew: null,
      timeorderNum: null,
      scrolllunbotime: true,
      // timeEnd: " ",
    };
  },
  components: {
    "toplogoCustomer-info": ToplogoCustomer,
    // "message-info": message,
    // "homehistoryWin-info": homehistoryWin,
    "homecomparisonchart-info": homecomparisonchart,
    "timeprizepool-info": Timeprizepool,
    "realTtimeorder-info": Realtimeorder,
    "guestrule-info": guestrule,
    // countDown,
  },
  
  
  created: async function () {
    await this.jennycurrent().then((res) => {
      this.ingstartnew = res.data.data.ing;
    }).catch(() => {
      this.ingstartnew = 0;
    });
  },
  mounted() {
    this.jennycurrentActivity()
  },
  methods: {
    istop() {
      document.getElementById("topidtime").scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    jennycurrent() {
      return new Promise((resolve, reject) => {
        currentActivity()
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
      });
    },
    jennycurrentActivity() {
      let that = this;
      currentActivity().then((res) => {
        let response = res.data.data;
        setActivity(
            JSON.stringify({
              activityId: res.data.data.activity.id,
              activityRoundId: res.data.data.activity_round.id,
            })
        );
        that.countdowndata = response
        that.startime = response.activity_round.guess_time_start; // 开始时间
        console.log('that.startime', that.startime)
        that.endtime = response.activity_round.guess_time_end; // 结束时间
        that.ingstart = res.data.data.ing
        that.timeorderNum = response.activity_round.result_order_count
        console.log("dddd", that.result_order_count);
        console.log("sunyanjie122222222222222", that.timeorderNum);
      });
    },
    isopenguestule() {
      this.onOpenguestule = true
    },
    isguessclose() {
      this.onOpenguestule = false
    },
    ishomechart() {
      this.onishomechart = true
    },
    ishomechartclose() {
      this.onishomechart = false
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.empty {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  
  .empty_content {
    position: relative;
    z-index: 1;
    color: #f1b147;
    
    .date {
      font-size: 32px;
      margin: 0;
    }
    
    .title {
      font-size: 36px;
      margin: 10px 0 50px;
      letter-spacing: 6px;
    }
  }
  
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100vw;
    height: 100vh;
  }
}

.syj_realttime {
  .sideicon {
    width: 268px;
    height: 578px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    
    .isonchart.tophome {
      top: 412px;
      
    }
  }
  
  .marquee {
    margin-top: -300px;
    z-index: 10;
  }
  
  ul {
    padding: 0;
    margin: 0;
    
    &.syj-content {
      padding: 16px 0;
    }
  }
  
  .syj_homeComparisonchart.syj_page-width {
    margin-bottom: 60px;
    padding-top: 40px;
    margin-top: 40px;
    z-index: 0;
  }
  
  .syj_realttime-content {
    background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/timeorderMian-banner.jpg") no-repeat;
    height: 350px;
    background-position: top center;
    background-size: inherit;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: -1;
  }
}

.syj-realtime-bottombg {
  padding-bottom: 30px;
  padding-top: 5px;
  display: inline-block;
  background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/homebg.jpg) repeat-y top center / contain  #2a0337;
  width: 100%;
  min-height: 100vh;
}

.feihome {
  display: none;
}

.syj_page-width {
  max-width: 1200px;
  position: relative;
  margin: auto;
}

.live-order {
  box-sizing: border-box;
  
  ::v-deep .syj_enter-login-content {
    box-sizing: border-box;
    padding: 20px 15px 0;
    
    .cursorPointer {
      img {
        width: 100px;
      }
      
      &.right {
        font-size: 14px;
        
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  
  ::v-deep .marquee {
    .vue-danmaku {
      height: 290px !important;
    }
  }
  
  ::v-deep .syj-realtime-bottombg {
    .syj_page-width {
      width: unset;
      margin: 0 15px;
      box-sizing: border-box;
    }
  }
  
  ::v-deep .time-record {
    padding: 0;
    
    li {
      padding: 0;
      
      > span {
        width: calc(100% / 3);
        
        &:nth-child(1) {
          display: none;
        }
        
        &:nth-child(4) {
          display: none;
        }
        
        &:nth-child(6) {
          display: none;
        }
        
        &.srcname {
          width: auto;
          padding: 0;
        }
      }
    }
    
    .relast {
      top: -40px;
    }
  }
}
</style>
