import { render, staticRenderFns } from "./Timeprizepool.vue?vue&type=template&id=0735edf7&scoped=true"
import script from "./Timeprizepool.vue?vue&type=script&lang=js"
export * from "./Timeprizepool.vue?vue&type=script&lang=js"
import style0 from "./Timeprizepool.vue?vue&type=style&index=0&id=0735edf7&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0735edf7",
  null
  
)

export default component.exports