<script>
import Toplogocustomer from "@/components/toplogoCustomer";

export default {
  name: "Toplogocustomer",
  extends: Toplogocustomer,
}
</script>

<template>
  <div class="syj_page-width toplogoCustomer">
    <div class="syj_enter-login-content">
      <div class="cursorPointer" @click="homeBlack">
        <img
            v-if="$route.path != '/home'"
            class="homelogo"
            src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/maiyuanlogo.png"
        />
      </div>
      <span @click="PersonalBlack" class="cursorPointer right">Hi！大奖预定人 {{ customer }} ~
        <img v-if="Image" :src="Image"/>
        <img v-else/>
      </span>
    </div>
  </div>
</template>

<style scoped lang="less">
.homelogo {
  width: 70px !important;
}
</style>
