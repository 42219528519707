<script>
import realtimeorder from "@/components/realTtimeorder/realTimeorder";
import {getActivity} from "@/units/activity";
import {getToken} from "@/units/token";
import maiyuanRequest from "@/units/maiyaunRuquest";

export default {
  name: "Realtimeorder",
  extends: realtimeorder,
  methods: {
    init() {
      if (this.timeorderNum > 0) {
        setTimeout(() => {
          this.numtotal = this.timeorderNum
        }, 500)
      } else {
        console.log('starttime', this.starttime)
        this.dataRefreh();
      }
      this.$bus.$on("suiji", (val) => {
        this.timeorder = val
        console.log("投注明细获取实时订单数量", val)
        
      })
      this.PersonalBetdetailsone();
    },
    PersonalBetdetailsone() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let apiAuth = getToken()
      let activityRoundId = activityInfo.activityRoundId || null;
      that.isloading = true
      console.log(that.select_all_info1)
      if (that.select_all_info1 == 1) {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          headers: {"Authori-zation": "Bearer " + apiAuth},
          params: {
            limit: 10,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          that.isloading = false;
          if (response.status == 400) {
            return
          }
          console.log("response.data.length", response.data.length)
          if (response.data.length > 0) {
            that.realtimeorderlist = response.data
            return
          } else {
            that.realtimeorderlist = response.data
          }
        });
      } else {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          params: {
            limit: 10,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          that.isloading = false;
          if (response.status == 400) {
            return
          }
          console.log("response.data.length", response.data.length)
          if (response.data.length > 0) {
            that.realtimeorderlist = response.data
            return
          }
        });
      }
    },
    PersonalBetdetails() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      let apiAuth = getToken();
      if (that.select_all_info1 == 1) {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          headers: {"Authori-zation": "Bearer " + apiAuth},
          params: {
            page: that.currentPage,
            limit: 10,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          if (response.status == 400) {
            return
          }
          if (response.data.length > 0) {
            response.data.forEach((e) => {
              that.realtimeorderlist.push(e);
            });
            that.currentPage++
          } else {
            this.issOpen = !this.issOpen;
          }
        });
      } else {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          params: {
            page: that.currentPage,
            limit: 10,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          if (response.status == 400) {
            return
          }
          if (response.data.length > 0) {
            response.data.forEach((e) => {
              that.realtimeorderlist.push(e);
            });
            that.currentPage++
          } else {
            this.issOpen = !this.issOpen;
          }
        });
      }
    },
  }
}
</script>

<template>
  <div class="syj_realTtimeorder">
    <div class="amz-preview-content">
      <ul id="amz-timezone" data-opacity="2">
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false">
          <img data-v-418dce2f=""
               src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/bj.png"
               data-raw-src="https://img.amz123.com/upload/index_icon/20220826/bj.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;北京&nbsp;</span><span data-v-418dce2f="" data-tz-offset="0"
                                                                  data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/md.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/md.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;美东&nbsp;</span><span data-v-418dce2f=""
                                                                  data-tz-offset="-13" data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/md.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/md.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;美西&nbsp;</span><span data-v-418dce2f=""
                                                                  data-tz-offset="-16" data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/yg.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/yg.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;英国&nbsp;</span><span data-v-418dce2f="" data-tz-offset="-8"
                                                                  data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/oz.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/oz.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;欧洲&nbsp;</span><span data-v-418dce2f="" data-tz-offset="-7"
                                                                  data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/rb.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/rb.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;日本&nbsp;</span><span data-v-418dce2f="" data-tz-offset="1"
                                                                  data-opacity="2"></span>
        </li>
      </ul>
    </div>
    <div class="syj_page-width">
      <div class="syj-commontitle">
        <title class="commom-title"><span>实时订单数</span></title>
      </div>
      <div class="syj-timeordernum">
        <p class="real-num">
          <!-- <span
            v-for="(timenum, key) in timetotalorder.toString().split('')"
            :key="key">
            {{ timenum }}
          </span> -->
          <span v-for="(item, index) in jackpotamount" :key="index">
        <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
          {{ indexs }}
        </div>
        </span>
        
        </p>
        <realtimeProgress-info :variable="select_all_info1"
                               @progress_updated="progress_updated1"></realtimeProgress-info>
      </div>
      
      <div class="syj_page-btn" v-if="1 == 6">
        <button @click="onOpenbet">
          <img src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/lijitouzhu.png"/>
        </button>
      </div>
      <!-- 投注 弹出层相关内容 start -->
      <div v-if="this.bets_lenght == 6">
        <Betpopsix-info @IsClosebetlist="IsClosebet" v-if="onOpenBetinfo">
        </Betpopsix-info>
      </div>
      <!-- 投注 弹出层相关内容 start -->
      <div v-else>
        <Betpop-info @IsClosebetlist="IsClosebet" v-if="onOpenBetinfo"></Betpop-info>
      </div>
      
      <div class="list-head">
        <div class="switch-con">
          <div class="switch-label">
            <CSwitch
                v-model="select_all_info1"
                @change="tab11"
                active-color="#dd0e19"
                inactive-color="#dd0e19"
                :active-value="1"
                :inactive-value="0"
            />
            <div class="content">
              <div :class="{ on: select_all_info1 === 0 }">全部</div>
              /
              <div :class="{ on: select_all_info1 === 1 }">本人</div>
            </div>
          </div>
        </div>
        
        <div class="time_content">{{ time_content }}</div>
      </div>
      
      <ul class="time-record">
        <li class="listfrist">
            <span v-for="(realtitle, index) in realtitlelist" :key="index">
              {{ realtitle.name }}
            </span>
          <div class="relast cursorPointer" :class="{'is-loading': isloading}" @click="refreshOrder">
            <div>刷新</div>
            <img src="~@/assets/images/timeorderrefresh.png">
          </div>
        </li>
        <li v-for="(realtimeorder, index) in realtimeorderlist" :key="index"
            :class="realtimeorder.eliminate_status == 1 ? 'gray' : 'active'">
          <span>{{ realtimeorder.company }}</span>
          <span>{{ realtimeorder.parent_department }}</span>
          <span class="srcname"><img :src="realtimeorder.avatar_url">{{ realtimeorder.name }}</span>
          <span>{{ realtimeorder.type }}</span>
          <span>{{ realtimeorder.award_number }}</span>
          <span>{{ realtimeorder.order_commitment }}</span>
        </li>
        
        <li class="last">
          <button v-if="!issOpen" @click="isOpen">查看更多></button>
          <button v-else>我是有底线的...</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="less">
.commom-title {
  width: 200px;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  background-size: contain;
}

.syj-timeordernum {
  width: 100%;
  
  .real-num {
    width: 100%;
    font-size: 30px;
    height: 50px;
    line-height: 50px;
    
    span {
      width: 35px;
      height: 50px;
      border-radius: 8px;
      
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: normal;
      }
    }
  }
  
  .progress {
    margin: 20px 20px;
    ::v-deep .progress-bar {
      transform: scale(0.9);
    }
    ::v-deep .progress__text {
      font-size: 13px !important;
    }
  }
}

::v-deep .list-head {
  margin: 30px 30px 20px;
  
  .switch-label {
    font-size: 20px;
  }
  
  .time_content {
    display: none;
  }
}

.switch-con {
  display: flex;
  
  .switch-label {
    display: flex;
    align-items: center;
    
    .content {
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-size: 13px;
      color: #fff;
    }
  }
}

.syj_realTtimeorder {
  ul {
    li {
      font-size: 14px;
      &.last {
        button {
          height: 30px;
          padding: 0 20px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
